import moment from 'moment';
import axios from 'axios';
import { DataTableSortOrderType } from 'primereact/datatable';
import {
  IBatchPageble,
  IBookingComments,
  IBookingListModel,
  IBookingTransactionModel,
  IBookingUIModel,
  IBulkBookingResponse,
  IParticipant,
  IUserLookupModel,
} from '../models/index';

import { IRefundListApiModel, IRefundModel } from '../models/Booking/RefundModel';
import { getHeaderWithBearerToken } from '../helpers';
import { IbackpackOffloadingList } from '../models/Booking/BackPackOffloadingModel';
import { BookingParticipantState, BookingState } from '../models/Booking/BookingParticipantsList';

export const bookingsBaseApi = `${process.env.REACT_APP_TMS_BACKEND_URL}/bookings`;
export const participantBaseApi = `${process.env.REACT_APP_TMS_BACKEND_URL}/participants`;

class BookingService {
  get = (pageNo: number, pageSize: number, searchSpec?: string, sortField?: string, sortOrder?: number) => {
    let url = `${bookingsBaseApi}?pageNo=${pageNo}&pageSize=${pageSize}`;
    if (searchSpec) url += `&searchQuery=${searchSpec}`;
    if (sortField) url += `&sortField=${sortField}`;
    if (sortOrder) url += `&sortOrderDescending=${sortOrder !== -1}`;

    return axios.get<IBatchPageble<IBookingListModel>>(url, { headers: getHeaderWithBearerToken() });
  };

  downloadBooking = (searchSpec?: string, sortField?: string, sortOrder?: number | DataTableSortOrderType) => {
    let url: string;

    if (searchSpec !== undefined && searchSpec !== '') {
      url = `${bookingsBaseApi}/downloads?searchQuery=${searchSpec}`;
    } else {
      // IF SEARCH_QUERY IS UNDEFINED, THE SEARCH RESULTS ARE RESTRICTED TO RETURN FROM CURRENT DATE
      url = `${bookingsBaseApi}/downloads?searchQuery=createdAt>'${moment().format('YYYY-MM-DDT00:00:00')}'`;
    }
    if (sortField) url += `&sortField=${sortField}`;
    if (sortOrder) url += `&sortOrderDescending=${sortOrder !== -1}`;

    return axios.get(url, {
      headers: getHeaderWithBearerToken(),
      responseType: 'blob',
    });
  };

  getParticipants = (bookingId: number) => {
    const url = `${bookingsBaseApi}/${bookingId}/participants`;
    return axios
      .get<IParticipant[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getParticipantById = (participantId: number) => {
    const url = `${participantBaseApi}/${participantId}`;
    return axios
      .get<IParticipant>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getParticipantDocument = (participantId: number, type: string) => {
    const url = `${participantBaseApi}/${participantId}/documents/${type}`;
    return axios.get(url, { headers: getHeaderWithBearerToken(), responseType: 'blob' }).then((res) => res.data);
  };

  getParticipantIdCardsForTheBatch = (batchId: number) => {
    const url = `${participantBaseApi}/${batchId}/id-cards-for-the-batches`;
    return axios.get(url, { headers: getHeaderWithBearerToken(), responseType: 'blob' }).then((res) => res.data);
  };

  getById = (bookingId: number) => {
    const url = `${bookingsBaseApi}/${bookingId}`;
    return axios
      .get<IBookingUIModel>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  deleteBatch = (batchId: number) => {
    const url = `${bookingsBaseApi}/${batchId}`;
    return axios
      .delete<void>(url, {
        params: {
          id: batchId,
        },
        headers: getHeaderWithBearerToken(),
      })
      .then((res) => res.data);
  };

  // TODO::KK BOOKING STATE NOT TO BE COMPLETED
  create = (data: IBookingUIModel) => {
    const bookingData = {
      id: 0,
      trekId: data.trekId,
      batchId: data.batchId,
      state: BookingState.COMPLETED,
      ownerUserId: data.ownerUserId.id,
      overseasBooking: data.overseasBooking,
      participants: this.mergeParticipants(data),
    };

    const url = `${bookingsBaseApi}`;
    return axios.post(url, bookingData, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  mergeParticipants = (data: IBookingUIModel, ownerParticipantId?: number) => {
    const users: any[] = [];
    data.participants?.forEach((x) => {
      users.push({
        id: x.participantId,
        userId: x.userId.id,
        bookingParticipantState: x.bookingParticipantState,
        insuranceOpted: x.insuranceOpted,
        name: '',
        amountPaid: 0,
        trekFeeForTheUser: 0,
        insuranceAmount: 0,
        participantComments: null,
        userDetailsForDisplay: null,
      });
    });

    // TODO::KK SHOULD THE `bookingParticipantState` Be `APPROVED`??
    users.push({
      id: ownerParticipantId,
      userId: data.ownerUserId.id,
      bookingParticipantState: BookingParticipantState.APPROVED,
      insuranceOpted: data.primaryParticipantInsurance === undefined ? false : data.primaryParticipantInsurance,
      name: '',
      amountPaid: 0,
      trekFeeForTheUser: 0,
      insuranceAmount: 0,
    });
    return users;
  };

  // TODO::KK BOOKING STATE NOT TO BE COMPLETED
  update = (bookingId: number, ownerParticipantId: number, data: IBookingUIModel) => {
    const owner = data.ownerUserId as IUserLookupModel;
    const bookingData = {
      id: bookingId,
      trekId: data.trekId,
      batchId: data.batchId,
      state: BookingState.COMPLETED,
      ownerUserId: owner.id,
      overseasBooking: data.overseasBooking,
      participants: this.mergeParticipants(data, ownerParticipantId),
    };
    const url = `${bookingsBaseApi}`;
    return axios.put(url, bookingData, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  saveBookingComments = (bookingId: number, comment: any, userId: number) => {
    const url = `${bookingsBaseApi}/${bookingId}/booking-comments`;
    const payload = {
      userId,
      commentText: comment.commentText,
      userName: '',
    };

    return axios.patch(url, payload, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  saveParticipantComments = (bookingId: number, participantId: number, comment: any, userId: number) => {
    const url = `${participantBaseApi}/${participantId}/comments`;
    const payload = {
      userId,
      commentText: comment.commentText,
    };

    return axios.patch(url, payload, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  fetchParticipantComments = (participantId: number | undefined) => {
    const url = `${participantBaseApi}/${participantId}/comments`;
    return axios
      .get<IBookingComments[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  fetchTransactions = (bookingId: number) => {
    const url = `${bookingsBaseApi}/${bookingId}/payment-transactions`;
    return axios
      .get<IBookingTransactionModel[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  createOrUpdateTransactions = (bookingId: number, data: IBookingTransactionModel[]) => {
    const dt = data.map((x) => {
      const frmData = {
        providerId: x.providerId,
        paymentMode: x.paymentMode,
        paymentAmount: x.paymentAmount,
        message: x.message,
        status: x.status,
        productInformation: x.productInformation,
        bankTransactionId: x.bankTransactionId,
        bookingId,
      };
      return frmData;
    });
    const url = `${bookingsBaseApi}/${bookingId}/payment-transactions`;
    return axios.patch(url, dt, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  createRefund = (bookingId: number, participantId: number, data: IRefundModel) => {
    const url = `${bookingsBaseApi}/${bookingId}/participants/${participantId}/refunds/${data.amount}`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  getRefunds = (bookingId: number, participantId: number) => {
    const url = `${bookingsBaseApi}/${bookingId}/participants/${participantId}/refunds`;
    return axios
      .get<IRefundListApiModel[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  approveBooking = (bookingId: number) => {
    const url = `${bookingsBaseApi}/${bookingId}/approve-booking`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  activateBooking = (bookingId: number) => {
    const url = `${bookingsBaseApi}/${bookingId}/activate-booking`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  completeBooking = (bookingId: number, sendNotification: boolean) => {
    const url = `${bookingsBaseApi}/${bookingId}/complete-bookings?sendNotification=${sendNotification}`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  sendPaymentReceipt = (bookingId: number) => {
    const url = `${bookingsBaseApi}/${bookingId}/send-payment-receipts-to-customer`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  cancelBooking = (bookingId: number, participantId: number, data?: any) => {
    const { sendNotifications = false, ...participantCancellation } = data;
    const url = `${bookingsBaseApi}/${bookingId}/participants/${participantId}/cancel-booking?sendNotifications=${sendNotifications}`;
    return axios.patch(url, participantCancellation, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  markParticipantAsNoShow = (participantId: number) => {
    const url = `${participantBaseApi}/${participantId}/no-show`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  createTransfer = (bookingId: number, batchId: number, sendNotifications = false) => {
    const url = `${bookingsBaseApi}/${bookingId}/transfer-booking/${batchId}?sendNotifications=${sendNotifications}`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  refreshWaitingList = () => {
    const url = `${bookingsBaseApi}/refresh-waiting-lists`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() });
  };

  updateTrekFee = (bookingId: number, participantId: number, id: number, trekFee: number, insuranceAmount: number) => {
    const url = `${bookingsBaseApi}/${bookingId}/participants/${participantId}`;
    const apiData = {
      id,
      trekFeeForTheUser: trekFee,
      insuranceAmount,
    };
    return axios.patch(url, apiData, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  getBackpackById = (bookingId: number) => {
    const url = `${bookingsBaseApi}/${bookingId}/backpack-offloading`;
    return axios.get<IbackpackOffloadingList>(url).then((res) => res.data);
  };

  getBulkBookingTemplate = () => {
    const url = `${bookingsBaseApi}/bulk-booking/csv-template`;
    return axios.get(url, {
      headers: getHeaderWithBearerToken(),
      responseType: 'blob',
    });
  };

  createBulkBooking = async (formData: FormData) => {
    const url = `${bookingsBaseApi}/bulk-booking`;
    return axios.post<IBulkBookingResponse>(url, formData, { headers: getHeaderWithBearerToken() });
  };
}

const batchServiceApi = new BookingService();
export default batchServiceApi; // exporting like a singleton
