/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { AutoComplete, AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import LookupService from '../../api/LookupService';

import { InputNumber } from 'primereact/inputnumber';
import { ICancellationReasonModel } from '../../models/Trek/CancellationReasonModel';
import BatchService from '../../api/BatchService';
import { IBatchApiModel } from '../../models/Batch/BatchApiModel';
import './BatchCancellation.scss';
import { IBatchCancellationModel } from '../../models/Batch/IBatchCancellationModel';
import Overlay from '../common/Overlay';
import { Checkbox } from 'primereact/checkbox';

const VOUCHER_PERCENTAGE = 'voucherPercentage';
const REFUND_PERCENTAGE = 'refundPercentage';
const OTHER_REASON = 'Others';

const BatchCancellation: React.FC<RouteComponentProps> = ({ history, match }) => {
  const params: any = match.params;
  const id: number = parseInt(params.id, 10);

  const [loading, setLoading] = useState(true);
  const toast = useRef(null);
  const [batchData, setBatchData] = useState<IBatchApiModel>();
  const [cancellationReasonList, setCancellationReasonList] = useState<ICancellationReasonModel[]>([]);
  const [cancellationReasonFilteredValues, setCancellationReasonFilteredValues] = useState<ICancellationReasonModel[]>(
    [],
  );
  const [selectedCancellationReason, setSelectedCancellationReason] = useState<ICancellationReasonModel | null>(null);
  const [isOtherReason, setIsOtherReason] = useState<boolean>(false);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        voucherPercentage: Yup.number().required('Voucher percentage is required'),
        cancelReason: Yup.string().required('Cancel reason is required'),
        cancelReasonElaborate: Yup.string().when('cancelReason', {
          is: (value: String) => value === 'OTHER',
          then: Yup.string().required('Cancel Reason Elaborate is required'),
        }),
      }),
    [],
  );

  const autoCancellationReasons = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setCancellationReasonFilteredValues([...cancellationReasonList]);
    } else {
      setCancellationReasonFilteredValues(
        cancellationReasonList.filter((cancellationReason: ICancellationReasonModel) => {
          return cancellationReason.reason.toLocaleLowerCase().includes(event.query.toLowerCase());
        }),
      );
    }
  };

  const updateCancellationReasonData = (cancellationReason: ICancellationReasonModel | null = null) => {
    setSelectedCancellationReason(!!cancellationReason ? cancellationReason : null);
    if (cancellationReason && cancellationReason.reason === OTHER_REASON) setIsOtherReason(true);
    else setIsOtherReason(false);
  };

  const { handleSubmit, reset, setValue, control, errors, formState, getValues } = useForm<IBatchCancellationModel>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: IBatchCancellationModel) => {
    createCancellation(data);
  };

  const createCancellation = (data: IBatchCancellationModel) => {
    setLoading(true);
    return BatchService.suspendBatch(id, data)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: 'Batch Cancellation Successful' });
        history.push(`/batches/view/${id}`);
      })
      .catch((res) => {
        const tst: any = toast?.current;
        if (res.response.data.message) tst.show({ severity: 'error', summary: `${res.response.data.message}` });
        else
          tst.show({
            severity: 'error',
            summary: 'Batch Cancellation failed;Re-try in few mins. ...If not succeeded contact support team',
          });
      })
      .finally(() => setLoading(false));
  };

  const validationSummary = (
    <div className="validation-summary-error">
      <pre>
        {Object.keys(errors).length > 0 && (
          <div>
            Please fill in the following required information:
            <ul>
              {Object.keys(errors).map((field) => (
                <li>{field}</li>
              ))}
            </ul>
          </div>
        )}
      </pre>
    </div>
  );

  const handleCancelClick = () => {
    history.push(`/batches/view/${id}`);
  };

  useEffect(() => {
    BatchService.getById(id).then((data) => {
      setBatchData(data);
      setValue('trekFee', data.trekFee);
      setValue(VOUCHER_PERCENTAGE, 100);
      setValue('voucherAmount', data.trekFee);
      setLoading(false);
    });
    const lookupService = LookupService;
    lookupService.getCancellationReasons().then((cancellationReasons) => {
      setCancellationReasonList(cancellationReasons);
    });
  }, []);

  // Adjust sum of voucherPercentage and refundPercentage calculations
  const calibrateCancellationPercentage = (activeField: string) => {
    const voucherPercentage = Number(getValues(VOUCHER_PERCENTAGE));
    const refundPercentage = Number(getValues(REFUND_PERCENTAGE));
    if (voucherPercentage + refundPercentage > 100) {
      if (activeField === VOUCHER_PERCENTAGE) {
        const newRefundPercentage = 100 - voucherPercentage;
        setValue(REFUND_PERCENTAGE, newRefundPercentage);
        calculateAndSetValueAmount(REFUND_PERCENTAGE, newRefundPercentage);
      } else if (activeField === REFUND_PERCENTAGE) {
        const newVoucherPercentage = 100 - refundPercentage;
        setValue(VOUCHER_PERCENTAGE, newVoucherPercentage);
        calculateAndSetValueAmount(VOUCHER_PERCENTAGE, newVoucherPercentage);
      }
    }
  };

  // Set the updated voucherAmount or refundAmount based on new valuePercentage
  const calculateAndSetValueAmount = (field: string, valuePercentage: number) => {
    const trekFee = Number(getValues('trekFee'));
    const remainAmount = trekFee - Number(getValues('taxDeductible'));
    const percentage = valuePercentage / 100;
    const valueAmount = percentage * remainAmount;
    if (field === VOUCHER_PERCENTAGE) setValue('voucherAmount', valueAmount);
    else if (field === REFUND_PERCENTAGE) setValue('refundAmount', valueAmount);
  };

  const onVoucherPercentChange = (valuePercentage: number) => {
    calibrateCancellationPercentage(VOUCHER_PERCENTAGE);
    calculateAndSetValueAmount(VOUCHER_PERCENTAGE, valuePercentage);
  };

  const onTaxChange = () => {
    const voucherPercentage = Number(getValues(VOUCHER_PERCENTAGE));
    const refundPercentage = Number(getValues(REFUND_PERCENTAGE));
    if (voucherPercentage > 0) {
      onVoucherPercentChange(voucherPercentage);
    } else if (refundPercentage > 0) {
      onRefundPercentChange(refundPercentage);
    }
  };

  const onRefundPercentChange = (valuePercentage: number) => {
    calibrateCancellationPercentage(REFUND_PERCENTAGE);
    calculateAndSetValueAmount(REFUND_PERCENTAGE, valuePercentage);
  };

  return (
    <>
      {loading && <Overlay text="Loading..." icon="pi-spinner" iconSpin />}
      <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
        <Toast ref={toast}></Toast>
        <div className="d-flex align-items-center details-title">
          <div className="flex-grow-1">
            <div className="display-1">Cancellation for - Batch Id {id}</div>
          </div>
        </div>
        <div className="card">
          <div className="p-grid">
            <div className="p-col-12 p-md-12 p-lg-8">
              <div className="card p-fluid">
                <h3>Batch Details </h3>
                <div className="card p-fluid">
                  <div className="p-field p-grid batchDetails">
                    <div className="detailsLabel">Trek Name</div>
                    <div className="detailsData">{batchData?.trek}</div>
                  </div>
                  <div className="p-field p-grid batchDetails">
                    <div className="detailsLabel">Capacity</div>
                    <div className="detailsData">{batchData?.capacity}</div>
                  </div>
                  <div className="p-field p-grid batchDetails">
                    <div className="detailsLabel">Total Confirmed Participants</div>
                    <div className="detailsData">{batchData?.numberOfConfirmedBooking}</div>
                  </div>
                  <div className="p-field p-grid">
                    <label htmlFor="trekFee" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                      Trek Fee Per Participant (Including GST):
                    </label>
                    <div className="p-col-12 p-md-10">
                      <Controller
                        name="trekFee"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <InputText readOnly={true} value={value} onChange={onChange} />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <h3>Refund </h3>
                <div className="card p-fluid">
                  {validationSummary}

                  <div className="p-field p-grid ">
                    <label htmlFor="taxDeductible" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0 ">
                      Tax deductible:
                    </label>
                    <div className="p-col-12 p-md-10">
                      <Controller
                        name="taxDeductible"
                        control={control}
                        defaultValue="0"
                        rules={{ required: true }}
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onValueChange={(e: any) => {
                              onChange(e.value);
                              onTaxChange();
                            }}
                            mode="decimal"
                            minFractionDigits={2}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="p-field p-grid ">
                    <label htmlFor={VOUCHER_PERCENTAGE} className="p-col-12 p-mb-2 p-md-2 p-mb-md-0 ">
                      Voucher Percentage:
                    </label>
                    <div className="p-col-12 p-md-10">
                      <Controller
                        name={VOUCHER_PERCENTAGE}
                        control={control}
                        defaultValue="0"
                        rules={{ required: true }}
                        render={({ onChange, value }) => (
                          <InputNumber
                            min={0}
                            max={100}
                            value={value}
                            onValueChange={(e: any) => {
                              onChange(e.value);
                              onVoucherPercentChange(e.value);
                            }}
                            mode="decimal"
                            minFractionDigits={1}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="p-field p-grid ">
                    <label htmlFor="voucherValidity" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0 ">
                      Voucher Validity (In Years):
                    </label>
                    <div className="p-col-12 p-md-10">
                      <Controller
                        name="voucherValidity"
                        control={control}
                        defaultValue="1"
                        rules={{ required: true }}
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onValueChange={(e: any) => onChange(e.value)}
                            mode="decimal"
                            min={1}
                            max={100}
                            minFractionDigits={1}
                            showButtons
                            buttonLayout="horizontal"
                            step={1}
                            decrementButtonClassName="p-button-danger"
                            incrementButtonClassName="p-button-success"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="p-field p-grid ">
                    <label htmlFor={REFUND_PERCENTAGE} className="p-col-12 p-mb-2 p-md-2 p-mb-md-0 ">
                      Refund Percentage:
                    </label>
                    <div className="p-col-12 p-md-10">
                      <Controller
                        name={REFUND_PERCENTAGE}
                        control={control}
                        defaultValue="0"
                        rules={{ required: true }}
                        render={({ onChange, value }) => (
                          <InputNumber
                            min={0}
                            max={100}
                            value={value}
                            onValueChange={(e: any) => {
                              onChange(e.value);
                              onRefundPercentChange(e.value);
                            }}
                            mode="decimal"
                            minFractionDigits={1}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="p-field p-grid ">
                    <label htmlFor="voucherAmount" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0 ">
                      Voucher Amount:
                    </label>
                    <div className="p-col-12 p-md-10">
                      <Controller
                        name="voucherAmount"
                        control={control}
                        defaultValue="0"
                        rules={{ required: true }}
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            disabled={true}
                            onValueChange={(e: any) => onChange(e.value)}
                            mode="currency"
                            currency="INR"
                            minFractionDigits={2}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="p-field p-grid ">
                    <label htmlFor="refundAmount" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0 ">
                      Refund Amount:
                    </label>
                    <div className="p-col-12 p-md-10">
                      <Controller
                        name="refundAmount"
                        control={control}
                        defaultValue="0"
                        rules={{ required: true }}
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            disabled={true}
                            mode="currency"
                            currency="INR"
                            onValueChange={(e: any) => onChange(e.value)}
                            minFractionDigits={2}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <h3>Cancellation Reason </h3>
                <div className="card p-fluid">
                  <div className="p-field p-grid ">
                    <label htmlFor="cancelReason" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0 ">
                      Cancellation Reason:
                    </label>
                    <div className="p-col-12 p-md-10">
                      <Controller
                        name="cancelReason"
                        control={control}
                        render={({ onChange, value }) => (
                          <AutoComplete
                            placeholder="Reason"
                            dropdown
                            value={selectedCancellationReason}
                            onChange={(e) => {
                              onChange(e.value?.name);
                              updateCancellationReasonData(e.value);
                            }}
                            suggestions={cancellationReasonFilteredValues}
                            completeMethod={autoCancellationReasons}
                            field="reason"
                          ></AutoComplete>
                        )}
                      />
                    </div>
                  </div>
                  {isOtherReason && (
                    <div className="p-field p-grid ">
                      <label htmlFor="cancelReasonElaborate" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0 ">
                        Cancellation Reason Elaborate:
                      </label>
                      <div className="p-col-12 p-md-10">
                        <Controller
                          name="cancelReasonElaborate"
                          control={control}
                          defaultValue=""
                          render={({ onChange, value }) => <InputText value={value} onChange={onChange} />}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="card p-fluid">
                <div className="p-field p-grid ">
                  <label htmlFor="sendNotifications" className="p-col-6 ">
                    Do you want to send notifications to participants:
                  </label>
                  <div className="">
                    <Controller
                      name="sendNotifications"
                      control={control}
                      render={({ onChange, value }) => (
                        <Checkbox checked={value} onChange={(e) => onChange(e.checked)} />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="p-cpl-12 p-grid p-justify-end p-mt-4">
                <div>
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-warning p-button-lg"
                    onClick={handleCancelClick}
                  />
                </div>
                <Button
                  type="submit"
                  label="Suspend Batch"
                  icon="pi pi-check"
                  className="p-mx-2 p-button-lg p-button-secondary"
                  disabled={loading}
                >
                  {loading && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default withRouter(BatchCancellation);
