import axios from 'axios';
import { pickBy } from 'lodash';
import { IBatchListModel, IBatchPageble, IUserLookupModel } from '../models';
import { BatchCommentModel, IBatchModel } from '../models/Batch/BatchFormModel';
import { IBatchApiModel } from '../models/Batch/BatchApiModel';
import { getHeaderWithBearerToken } from '../helpers';
import { IBatchFilter } from '../models/Batch/IBatchFilter';
import { IBatchCancellationModel } from '../models/Batch/IBatchCancellationModel';

export const batchBaseApi = `${process.env.REACT_APP_TMS_BACKEND_URL}/batches`;

class BatchService {
  get = (
    pageNo: number,
    pageSize: number,
    batchesFilter?: IBatchFilter,
    sortField?: string,
    sortOrderDescending?: boolean,
  ) => {
    return axios.get<IBatchPageble<IBatchListModel>>(batchBaseApi, {
      headers: getHeaderWithBearerToken(),
      params: {
        pageNo,
        pageSize,
        sortField,
        sortOrderDescending,
        ...pickBy(batchesFilter, (val) => {
          return val !== undefined;
        }),
      },
    });
  };

  getById = (batchId: number) => {
    const url = `${batchBaseApi}/${batchId}`;
    return axios
      .get<IBatchApiModel>(url, {
        params: {
          id: batchId,
        },
        headers: getHeaderWithBearerToken(),
      })
      .then((res) => res.data);
  };

  deleteBatch = (batchId: number) => {
    const url = `${batchBaseApi}/${batchId}`;
    return axios
      .delete<void>(url, {
        params: {
          id: batchId,
        },
        headers: getHeaderWithBearerToken(),
      })
      .then((res) => res.data);
  };

  createTransfer = (fromBatchId: number, toBatchId: number, sendNotifications = false) => {
    const url = `${batchBaseApi}/${fromBatchId}/transfer/${toBatchId}?sendNotifications=${sendNotifications}`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  doClose = (batchId: number) => {
    const url = `${batchBaseApi}/${batchId}/close-batches`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  doReOpen = (batchId: number) => {
    const url = `${batchBaseApi}/${batchId}/reopen-batches`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  fetchBatchAuditView = (batchId: number) => {
    const url = `${batchBaseApi}/${batchId}/audits`;
    return axios.get<IBatchApiModel[]>(url, {
      headers: getHeaderWithBearerToken(),
    });
  };

  create = (data: IBatchModel) => {
    const batchData = {
      trekId: data.trekId,
      startDate: new Date(data.startDate.getTime() + Math.abs(data.startDate.getTimezoneOffset() * 60000)),
      endDate: new Date(data.endDate.getTime() + Math.abs(data.endDate.getTimezoneOffset() * 60000)),
      capacity: data.capacity,
      waitCapacity: data.waitCapacity,
      seasonId: data.seasonId?.id,
      promoterId: data.promoter?.id,
      description: data.seasonId?.id,
      whatsappGroupLink: data.whatsappGroupLink,
      preTrekBriefingLink: data.preTrekBriefingLink,
      privateBatch: data.promoter != null,
      multiBatch: data.multiBatchEnabled,
      trekLeaderIds: this.buildUsersList(data.trekLeaders),
      specialTrekType: data.specialTrek,
    };

    const url = `${batchBaseApi}`;
    return axios.post(url, batchData, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  saveBatchComments = (batchId: number, commentText: string) => {
    const url = `${batchBaseApi}/${batchId}/comments`;
    return axios.post(url, commentText, { headers: getHeaderWithBearerToken() });
  };

  fetchAllComments = (batchId: number) => {
    const url = `${batchBaseApi}/${batchId}/comments`;
    return axios.get<BatchCommentModel[]>(url, {
      headers: getHeaderWithBearerToken(),
    });
  };

  buildUsersList = (selectedUsers: IUserLookupModel[]) => {
    const items: number[] = [];
    selectedUsers?.map((x) => items.push(x.id));

    return items;
  };

  update = (batchId: number, data: IBatchModel) => {
    const batchData = {
      id: batchId,
      trekId: data.trekId,
      startDate: new Date(data.startDate.getTime() + Math.abs(data.startDate.getTimezoneOffset() * 60000)),
      endDate: new Date(data.endDate.getTime() + Math.abs(data.endDate.getTimezoneOffset() * 60000)),
      capacity: data.capacity,
      waitCapacity: data.waitCapacity,
      seasonId: data.seasonId?.id,
      promoterId: data.promoter?.id,
      description: data.seasonId?.id,
      whatsappGroupLink: data.whatsappGroupLink,
      preTrekBriefingLink: data.preTrekBriefingLink,
      privateBatch: data.promoter != null,
      multiBatchEnabled: true,
      trekLeaderIds: this.buildUsersList(data.trekLeaders),
      specialTrekType: data.specialTrek,
    };

    const url = `${batchBaseApi}`;
    return axios.put(url, batchData, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  updateBookingStatus = (batchId: number) => {
    const url = `${batchBaseApi}/${batchId}/bookings-closed`;
    return axios.patch(url, {}, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  suspendBatch = (batchId: number, bookingCancellation: IBatchCancellationModel) => {
    const { sendNotifications = false, ...batchCancellation } = bookingCancellation;
    const url = `${batchBaseApi}/${batchId}/suspend?sendNotifications=${sendNotifications}`;

    return axios.patch(url, batchCancellation, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };
}

const batchServiceApi = new BatchService();
export default batchServiceApi; // exporting like a singleton
